<template>
    <div class="apartment-filter-mobile">
        <div id="filter_header" class="filters-header">
            <div @click="toggleFilter(true)" class="item">
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.503906" width="21" height="4" rx="2" fill="#182236"/>
                    <rect x="4.50391" y="7" width="13" height="4" rx="2" fill="#182236"/>
                    <rect x="7.00391" y="14" width="8" height="4" rx="2" fill="#182236"/>
                </svg>

                <span class="title">Filter</span>
            </div>
            <div @click="showSort=true" class="item">
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8835 15.6601C15.8087 15.4593 15.6094 15.3339 15.4103 15.3339H13.6665V8.45287C13.6665 8.17682 13.4423 7.92603 13.1682 7.92603H10.5277C10.2536 7.92603 10.0295 8.15178 10.0295 8.45287V15.3339H8.28567C8.08636 15.3339 7.88706 15.4593 7.81247 15.6601C7.73768 15.8608 7.78761 16.0615 7.91212 16.2119L11.4992 19.8496C11.6985 20.0503 12.0223 20.0503 12.2216 19.8496L15.8087 16.2119C15.9332 16.0615 15.9583 15.8357 15.8835 15.6601Z"
                        fill="#182236"/>
                    <path
                        d="M6.49629 13.5474V4.66637H8.23504C8.43377 4.66637 8.6325 4.54097 8.70687 4.34025C8.78145 4.13953 8.73166 3.93881 8.60751 3.78837L5.03082 0.150543C4.83209 -0.0501809 4.50921 -0.0501809 4.3105 0.150543L0.733804 3.78816C0.584862 3.93859 0.559866 4.16456 0.634441 4.34004C0.709017 4.54076 0.907742 4.66616 1.10627 4.66616H2.84502V13.5472C2.84502 13.8233 3.06854 14.0741 3.34185 14.0741H5.97469C6.24778 14.0739 6.4963 13.8231 6.4963 13.5472L6.49629 13.5474Z"
                        fill="#182236"/>
                </svg>

                <span class="title">Sort</span>
            </div>
        </div>
        <Teleport to="body">
            <div class="apartment-filter-mobile-content">
                <Transition name="filter">
                    <ApartmentFilterSection v-if="showFilter"
                                            @close="toggleFilter(false)"
                                            :projects="projects"/>
                </Transition>
                <Transition name="filter">
                    <div v-if="showSort" class="sort-container">
                        <div class="mobile-header">
                            <div @click="showSort=false" class="reset-container">
                                <img src="../../../../assets/icons/close-icon.png" alt="">
                                <span class="title">{{ $fn.tr('Sort') }}</span>
                            </div>
                        </div>

                        <h2 class="header-title fira">{{ $fn.tr('Sort By') }}</h2>
                        <div class="sort-list">
                        <span v-for="item in sortOption"
                              @click="sortFlats(item)"
                              class="title">{{ item.label }}</span>
                        </div>
                        <h2 class="header-title fira cur-header">{{ $fn.tr('Currency') }}</h2>
                        <div class="currency-list">
                        <span v-for="item in currencyOption"
                              @click="changeCurrency(item)"
                              class="title">{{ item.code }}</span>
                        </div>
                    </div>
                </Transition>
            </div>
        </Teleport>
        <Teleport to="body">
            <Transition>
                <div v-if="showMobileFilter" class="filters-header fixed-filter">
                    <div @click="toggleFilter(true)" class="item">
                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.503906" width="21" height="4" rx="2" fill="white"/>
                            <rect x="4.50391" y="7" width="13" height="4" rx="2" fill="white"/>
                            <rect x="7.00391" y="14" width="8" height="4" rx="2" fill="white"/>
                        </svg>

                        <span class="title">Filter</span>
                    </div>
                    <div @click="showSort=true" class="item">
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.8835 15.6601C15.8087 15.4593 15.6094 15.3339 15.4103 15.3339H13.6665V8.45287C13.6665 8.17682 13.4423 7.92603 13.1682 7.92603H10.5277C10.2536 7.92603 10.0295 8.15178 10.0295 8.45287V15.3339H8.28567C8.08636 15.3339 7.88706 15.4593 7.81247 15.6601C7.73768 15.8608 7.78761 16.0615 7.91212 16.2119L11.4992 19.8496C11.6985 20.0503 12.0223 20.0503 12.2216 19.8496L15.8087 16.2119C15.9332 16.0615 15.9583 15.8357 15.8835 15.6601Z"
                                fill="white"/>
                            <path
                                d="M6.49629 13.5474V4.66637H8.23504C8.43377 4.66637 8.6325 4.54097 8.70687 4.34025C8.78145 4.13953 8.73166 3.93881 8.60751 3.78837L5.03082 0.150543C4.83209 -0.0501809 4.50921 -0.0501809 4.3105 0.150543L0.733804 3.78816C0.584862 3.93859 0.559866 4.16456 0.634441 4.34004C0.709017 4.54076 0.907742 4.66616 1.10627 4.66616H2.84502V13.5472C2.84502 13.8233 3.06854 14.0741 3.34185 14.0741H5.97469C6.24778 14.0739 6.4963 13.8231 6.4963 13.5472L6.49629 13.5474Z"
                                fill="white"/>
                        </svg>

                        <span class="title">Sort</span>
                    </div>
                </div>
            </Transition>
        </Teleport>
    </div>
</template>


<script setup>
import ApartmentFilterSection from '../Parts/ApartmentFilterSection'
import {useMenuStore} from "../../../../store/pinia/menu";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {useProjects} from "../../../../store/pinia/projects";
import fn from "@/helpers/func";

let projectStore = useProjects()
let props = defineProps({
    projects: {
        type: Array,
        required: true
    }
})

const menuStore = useMenuStore()

let sortOption = [
    {
        label: fn.tr('Price: Low to High'),
        orderBy: 'asc',
        value: '1',
        orderField: 'price'
    },
    {
        label: fn.tr('Price: High to Low'),
        orderBy: 'desc',
        value: '2',
        orderField: 'price'
    },
    {
        label: fn.tr('Area: Low to High'),
        orderBy: 'asc',
        value: '3',
        orderField: 'area'
    },
    {
        label: fn.tr('Area: High to Low'),
        orderBy: 'desc',
        value: '4',
        orderField: 'area'
    },
]

let usd = computed(() => menuStore?.indx.usd?.usd)

let currencyOption = [
    {
        code: 'GEL',
        value: 1
    },
    {
        code: 'USD',
        value: usd.value
    },
]
let showFilter = ref(false)
let showSort = ref(false)
let mobileScrollTop = ref(0)
let isQueryEmpty = computed(() => {
    return route.query // 👈 null and undefined check
        && Object.keys(route.query).length === 0
        && Object.getPrototypeOf(route.query) === Object.prototype
})
let showMobileFilter = computed(() => isQueryEmpty && window.innerWidth < 1024 && mobileScrollTop.value > 150)

const mobileScroll = () => {
    if (window.innerWidth >= 1024) return
    let item = document.querySelector('#app')

    item.addEventListener('scroll', (e) => {
        mobileScrollTop.value = item.scrollTop
    })
}
const sortFlats = (value) => {
    projectStore.setQueryParameters({
        orderBy: value.orderBy,
        orderField: value.orderField,
    })
    projectStore.getFlats()
    showSort.value = false
}
const toggleFilter = (val) => {
    showFilter.value = val
}
const changeCurrency = (item) => {
    projectStore.updateFlatsPrice(item)
    showSort.value = false
}

onMounted(() => {
    mobileScroll()
})
onBeforeUnmount(()=>{

})
</script>

<style lang="scss">
.apartment-filter-mobile {
    display: none;
    @media only screen and (max-width: 1023px) {
        display: block;
        .filter-section {
            display: block;
        }
    }

    .filters-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 21px;
        border-bottom: 2px solid #BFBFBF;

        .item {
            display: flex;
            align-items: center;

            .title {
                font-weight: 700;
                font-size: 13px;
                font-family: 'FiraGO';
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $primaryNavy;
            }

            svg {
                margin-right: 8px;
            }
        }
    }
}

.fixed-filter {
    position: fixed;
    bottom: 20px;
    height: 42px;
    background: $primaryNavy;
    border-radius: 30px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;

    .item {
        display: flex;
        align-items: center;
        padding-right: 21px;

        .title {
            font-weight: 700;
            font-size: 13px;
            font-family: 'FiraGO';
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: white;
        }

        svg {
            margin-right: 8px;
        }

        &:last-child {
            padding-left: 21px;
            padding-right: 0;
            border-left: 1px solid white;
        }
    }

}

.apartment-filter-mobile-content {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    .el-scrollbar {
        display: block;
        background: white;
        min-height: 100vh;
        padding-bottom: 20px;

        .image-container {
            display: none;
        }
    }

    .currency-list {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .title {
            display: block;
            font-family: 'FiraGO';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: $primaryNavy;
            margin-left: 10px;
            cursor: pointer;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .header-title {
        &.cur-header {
            margin-top: 21px;
        }
    }

    .sort-container {
        width: 60%;
        background: white;
        height: 100vh;
        padding: 20px;

        @media only screen and (max-width: 767px) {
            width: 100%;
        }

        .mobile-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 21px;
            margin-bottom: 20px;
            border-bottom: 2px solid rgba(24, 34, 54, 0.2);

            .reset-container {
                display: flex;

                .title {
                    margin-left: 8px;
                    font-weight: 700;
                    font-size: 13px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    font-family: FiraGO;
                    display: block;
                }
            }
        }

        .header-title {
            font-weight: 700;
            font-size: 21px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }

        .sort-list {
            padding-top: 10px;

            .title {
                display: block;
                font-family: 'FiraGO';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: $primaryNavy;
                margin-top: 10px;
                cursor: pointer;
            }
        }
    }
}

.filter-enter-active,
.filter-leave-active {
    opacity: 1;
    transition: opacity 0.5s ease-out;
    transform: translateX(-0%);
}

.filter-enter-from,
.filter-leave-to {
    opacity: 0;
    transform: translateX(-100%);
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .apartment-filter-mobile-content {
            .el-scrollbar {
                background: $primaryNavy;
            }
        }

        .filters-header {
            svg, path, rect {
                fill: white !important;
            }

            .title {
                color: white !important;
            }
        }
    }
}
</style>
